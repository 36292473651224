// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Api_Country from "../../../../api/countries/Api_Country.res.js";
import * as Api_Provider from "../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocality from "./common/LocationsIndexLocality.res.js";
import * as LocationsIndexProvider from "./common/LocationsIndexProvider.res.js";
import * as LocationsIndexLocalitiesScss from "./LocationsIndexLocalities.scss";

var css = LocationsIndexLocalitiesScss;

function LocationsIndexLocalities(props) {
  var updateLocalities = props.updateLocalities;
  var updateProviders = props.updateProviders;
  var providers = props.providers;
  var initialState = React.useMemo((function () {
          return {
                  status: "FetchingLocalities",
                  providerStatus: {
                    TAG: "Ready",
                    _0: providers
                  }
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "SucceedLocalitiesFetch") {
              return {
                      TAG: "Update",
                      _0: {
                        status: {
                          TAG: "Ready",
                          _0: action._0
                        },
                        providerStatus: state.providerStatus
                      }
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: state.status,
                        providerStatus: {
                          TAG: "Ready",
                          _0: action._0
                        }
                      }
                    };
            }
          }
          switch (action) {
            case "FetchLocalities" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api_Country.fetchAllCountries(), (function (x) {
                                    if (x.TAG === "Ok") {
                                      var res = x._0;
                                      dispatch({
                                            TAG: "SucceedLocalitiesFetch",
                                            _0: res
                                          });
                                      return updateLocalities(res.countries.length);
                                    }
                                    SentryLogger.error1({
                                          rootModule: "LocationsIndexLocalities",
                                          subModulePath: /* [] */0,
                                          value: "make",
                                          fullPath: "LocationsIndexLocalities.make"
                                        }, "LocationsIndexLocalities" + "::FetchLocalities::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch("FailLocalitiesFetch");
                                  }));
                          })
                      };
            case "FailLocalitiesFetch" :
                return {
                        TAG: "Update",
                        _0: {
                          status: "FailedLocalitiesFetch",
                          providerStatus: state.providerStatus
                        }
                      };
            case "FetchProviders" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api_Provider.fetchAllProviders(), (function (x) {
                                    if (x.TAG === "Ok") {
                                      var res = x._0;
                                      dispatch({
                                            TAG: "SucceedProvidersFetch",
                                            _0: res
                                          });
                                      return updateProviders(res.providers.length);
                                    }
                                    SentryLogger.error1({
                                          rootModule: "LocationsIndexLocalities",
                                          subModulePath: /* [] */0,
                                          value: "make",
                                          fullPath: "LocationsIndexLocalities.make"
                                        }, "LocationsIndexLocalities" + "::FetchProviders::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch("FailProvidersFetch");
                                  }));
                          })
                      };
            case "FailProvidersFetch" :
                return {
                        TAG: "Update",
                        _0: {
                          status: state.status,
                          providerStatus: "FailedProvidersFetch"
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchLocalities");
        }), []);
  var res = state.status;
  var tmp;
  if (typeof res !== "object") {
    tmp = res === "FetchingLocalities" ? null : JsxRuntime.jsx("div", {
            children: "Something went wrong.",
            className: css.failLocalities
          });
  } else {
    var res$1 = res._0;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(H2.make, {
                  className: css.localitiesTitle,
                  children: "Top " + String(res$1.countries.length) + " " + props.localitiesTitle
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    Belt_Array.mapWithIndex(res$1.countries, (function (index, country) {
                            return JsxRuntime.jsx(LocationsIndexLocality.make, {
                                        name: country.name,
                                        href: Routes_Country.show(country.slug),
                                        totalLocations: country.totalLocations
                                      }, String(index));
                          })),
                    JsxRuntime.jsx("a", {
                          children: "View All Countries",
                          href: Routes_Country.index
                        })
                  ],
                  className: css.localitiesList
                })
          ]
        });
  }
  var res$2 = state.providerStatus;
  var tmp$1;
  tmp$1 = typeof res$2 !== "object" ? (
      res$2 === "FetchingProviders" ? null : JsxRuntime.jsx("div", {
              children: "Something went wrong.",
              className: css.failLocalities
            })
    ) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(H2.make, {
                  className: css.localitiesTitle,
                  children: props.providersTitle + ": Top Data Center Providers"
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    Belt_Array.mapWithIndex(res$2._0.providers, (function (index, provider) {
                            return JsxRuntime.jsx(LocationsIndexProvider.make, {
                                        name: provider.name,
                                        slug: provider.slug,
                                        totalLocations: provider.totalLocations
                                      }, String(index));
                          })),
                    JsxRuntime.jsx("a", {
                          children: "View All Providers",
                          href: Routes_Provider.index
                        })
                  ],
                  className: css.providersList
                })
          ]
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.localities
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp$1,
                      className: css.providers
                    })
              ],
              className: css.localitiesContainer
            });
}

var make = LocationsIndexLocalities;

export {
  css ,
  make ,
}
/* css Not a pure module */
